import { WEEKS, WEEKS_LONG } from "@/constants/calendar";

export default class TimeSlot {
  constructor(slotData) {
    this.id = slotData.id;
    this.datetime = new Date(slotData.datetime);
    this.rawDateTime = slotData.datetime;
    this.isFree = slotData.is_free;
    this.notary = slotData.notary;
  }

  getTime() {
    return this.datetime.toTimeString().substring(0, 5);
  }

  getDate() {
    return this.datetime.toLocaleDateString();
  }

  setDate(datetime) {
    this.datetime = new Date(datetime);
  }

  getDay() {
    return this.datetime.toLocaleString('ru', {
      month: 'long',
      day: 'numeric',
    });
  }

  getWeekDay() {
    const weekDayIndex = this.dateTime.getDay();

    return {
      index: weekDayIndex,
      short: WEEKS[weekDayIndex],
      long: WEEKS_LONG[weekDayIndex],
    };
  }

  checkIsArchived() {
    const now = new Date();
    const timeLeft = this.datetime - now;
    return timeLeft < 0;
  }
}
