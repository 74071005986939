import axios from 'axios';


export default function initApiService(options) {
  const { apiUrl, apiAuthHeader } = options;
  const API_AUTH_HEADER = apiAuthHeader || process.env.VUE_APP_API_AUTH_HEADER;
  const API_URL = apiUrl || process.env.VUE_APP_API_URL;

  function getHeaders() {
    const header = {};

    if (API_AUTH_HEADER) {
      header['Authorization'] = API_AUTH_HEADER;
    }

    return header;
  }


  const config = {
    baseURL: API_URL,
    headers: getHeaders(),
  };


  const api = axios.create(config);


  return {
    get(resource) {
      return api.get(resource);
    },

    post(resource, data) {
      return api.post(resource, data);
    },

    put(resource, data) {
      return api.put(resource, data);
    },

    delete(resource) {
      return api.delete(resource);
    },

    customRequest(data) {
      return api(data);
    },
  };
}




