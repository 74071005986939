<template>
  <div
    v-click-outside="onOutsideClick"
    class="slot-picker"
    :class="{
      'slot-picker_active': isActive,
    }"
  >
    <!-- hidden inbput for a value -->
    <input
      type="text"
      v-bind="$attrs"
      hidden
      :name="name"
      :value="value"
    />


    <!-- should looks like input -->
    <div
      class="slot-picker__label label"

      :class="[
        { 'label_active': isActive },
      ]"
      tabindex="0"
      @focus="isActive = true"
    >
      <span
        class="label__span"
        :class="[
          { 'label__span_placeholder': !value },
        ]"
      >{{ value ? displayedValue : placeholder }}</span>
      <svg
        class="label__glyph"
        width="13" height="13" xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#glyph--calendar" />
      </svg>
    </div>



    <keep-alive>
      <drop-down
        v-if="isActive"
        class="slot-picker__drop-down"
        :time-slot-list="timeSlotList"
        :time-slot.sync="timeSlot"
      />
    </keep-alive>

    <svg class="slot-picker__svg-defs" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <symbol id="glyph--calendar" viewBox="0 0 13 13">
          <path d="M12.5 12.5H0.5V2.5H12.5V12.5Z" fill="none" stroke="currentColor"/>
          <path d="M12.5 5.5H0.5V2.5H12.5V5.5Z" fill="none" stroke="currentColor"/>
          <path d="M3 2V0" fill="none" stroke="currentColor"/>
          <path d="M10 2V0" fill="none" stroke="currentColor"/>
        </symbol>

        <symbol id="glyph--angle-left" viewBox="0 0 7 12">
          <path d="M6 11L0.999999 6L6 1" fill="none" stroke="currentColor"/>
        </symbol>

        <svg id="glyph--triangle-left" viewBox="0 0 4 8">
          <path d="M2.99603e-07 3.89743L4 1.11464e-07L4 8L2.99603e-07 3.89743Z" fill="currentColor"/>
        </svg>
      </defs>
    </svg>
  </div>
</template>


<script>
import vClickOutside from 'v-click-outside';
import DropDown from '@/components/drop-down.vue';
import initApiService from '@/services/api';
import TimeSlot from '@/services/time-slot';


export default {
  name: 'SlotPicker',

  directives: {
    clickOutside: vClickOutside.directive,
  },


  components: {
    DropDown,
  },


  props: {
    placeholder: {
      type: String,
      default: 'Выберите время',
    },

    notaryId: {
      type: Number,
      default: 0,
    },

    name: {
      type: String,
      default: 'timeslot',
    },

    onChange: {
      type: Function,
      default: null,
    },

    apiUrl: {
      type: String,
      default: process.env.VUE_APP_API_URL,
    },

    apiAuthHeader: {
      type: String,
      default: process.env.VUE_APP_API_AUTH_HEADER,
    },
  },


  data() {
    const ApiService = initApiService({
      apiUrl: this.apiUrl,
      apiAuthHeader: this.apiAuthHeader,
    });

    return {
      isActive: false,

      timeSlot: null,
      timeSlotList: {},

      ApiService,
    };
  },



  computed: {
    value() {
      if (!this.timeSlot) {
        return null;
      }
      return this.timeSlot.rawDateTime;
    },


    displayedValue() {
      if (!this.timeSlot) {
        return null;
      }

      return this.timeSlot.datetime.toLocaleString("ru", {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
  },


  methods: {
    onOutsideClick() {
      this.isActive = false;
    },

    async getAvailableDates() {
      const response = await this.ApiService.get(`/?notary=${this.notaryId}`)
      return response.data;
    },

    adaptTimeSlots(availableDates) {
      return new Promise((resolve, reject) => {
        const timeSlotList = availableDates.reduce((timeSlotList, slot) => {
          const timeSlot = new TimeSlot(slot);
          const date = timeSlot.getDate();

          if (timeSlotList[date]) {
            timeSlotList[date].push(timeSlot);
          } else {
            timeSlotList[date] = [ timeSlot ];
          }

          return timeSlotList;
        }, {/* timeSlotList == new object */});

        resolve(timeSlotList);
      });
    },
  },


  watch: {
    timeSlot(value) {
      if (typeof this.onChange === 'function') {
        this.onChange(value);
      }

      if (value) {
        this.isActive = false;
      }
    },
  },

  async created() {
    // request current notary timeslots
    const rawAvailableDates = await this.getAvailableDates();
    const timeSlotList = await this.adaptTimeSlots(rawAvailableDates);

    this.$set(this, 'timeSlotList', timeSlotList);
  },
};
</script>


<style lang="sass" scoped>
// wrapper
.slot-picker
  position: relative
  z-index: 0
  box-sizing: border-box

.slot-picker_active
  z-index: 2

// caption / placeholder
.label
  display: flex
  box-sizing: border-box
  height: 3.75rem
  border: 1px solid $gray-border
  border-radius: 0.3125rem
  padding: 0 1.25rem
  font-size: 1.125rem
  align-items: center


  &:focus
    border-color: $gray-dark


.label_active
  border-color: $gray-dark
  border-bottom-color: transparent
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0

.label__span
.label__span_placeholder
  color: $gray-dark

.label__glyph
  position: absolute
  display: block
  width: 13/16 * 1rem
  height: 13/16 * 1rem
  right: 1rem

.slot-picker__drop-down
  position: absolute
  z-index: 1
  top: calc(100% - 2px)
  left: 0
  right: 0
  border: 1px solid $gray-dark
  border-radius: 0.3125rem
  border-top-left-radius: 0
  border-top-right-radius: 0
  border-top-color: transparent
  transform: translateZ(0)
  // width: 100%


.slot-picker__svg-defs
  display: none
</style>
