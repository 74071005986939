<template>
  <date-pick
    :is-date-disabled="checkDateIsUnavailable"
    v-model="innerDate"
    :has-input-element="false"
    :weekdays="WEEKS"
    :months="MONTHS"
    :format="'DD.MM.YYYY'"

    @input="onInput"
  ></date-pick>
</template>

<script>
import DatePick from "vue-date-pick";
import {
  WEEKS,
  WEEKS_LONG,
  MONTHS,
} from "@/constants/calendar";


const DATE = new Date();


export default {
  name: 'VCalendar',


  components: {
    DatePick,
  },


  model: {
    prop: 'date',
    event: 'update:date',
  },


  props: {
    date: {
      type: [String, Date],
      default: DATE.toLocaleDateString(),
    },

    timeSlotList: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      WEEKS,
      MONTHS,
      innerDate: this.date ? this.date.toLocaleDateString() : null,
    };
  },


  computed: {
    dateInstance() {
      if (!this.date) {
        return null;
      }

      return new Date(this.date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3"));
    }
  },


  methods: {
    checkDateIsUnavailable(date) {
      if (!this.timeSlotList) {
        return true;
      }

      const dateString = date.toLocaleDateString();
      const availableSlots = this.timeSlotList[dateString];

      return !availableSlots || [].every.call(availableSlots, slot => {
        return !slot.isFree || slot.checkIsArchived();
      });
    },

    onInput(value) {
      const date = new Date(value.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3"))

      this.$emit('update:date', date);
    }
  },


  watch: {
    date(value) {
      const date = value ? value.toLocaleDateString() : value;

      if (+date === +this.innerDate) {
        return;
      }

      this.$set(this, 'innerDate', date);
    },
  },
};
</script>
