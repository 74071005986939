<template>
  <div class="drop-down">
    <v-calendar
      v-if="phase === 'date'"
      :date="innerDate"
      @update:date="onDateSelected"
      :time-slot-list="timeSlotList"
    />

    <v-times
      v-if="phase === 'time'"
      :date="innerDate"
      :time-slot-list="timeSlotList"
      :time-slot.sync="innerTimeSlot"

      @back="phase = 'date'"
      @change-date="changeDate"
    />
  </div>
</template>


<script>
import VCalendar from '@/components/v-calendar.vue';
import VTimes from '@/components/v-times.vue';
import TimeSlot from '@/services/time-slot';


export default {
  name: 'DropDown',
  components: {
    VCalendar,
    VTimes,
  },

  props: {
    timeSlotList: {
      type: Object,
      defatult: () => ({}),
    },

    timeSlot: {
      type: TimeSlot,
      default: null,
    },
  },


  data() {
    return {
      innerDate: null,
      innerTimeSlot: this.timeSlot,
      phase: this.time ? 'time' : 'date',
    };
  },


  methods: {
    onDateSelected(date) {
      this.$set(this, 'innerDate', date);
      this.phase = 'time';
    },

    changeDate(days=0) {
      const date = new Date(this.innerDate);
      date.setDate(date.getDate() + days);
      this.$set(this, 'innerDate', date);
    },
  },

  watch: {
    timeSlot(value) {
      if (this.innerTimeSlot && this.innerTimeSlot.id === value.id) {
        return;
      }

      this.$set(this, 'innerTimeSlot', value);
    },

    // innerDate(value) {
    //   if (value === this.date) {
    //     return;
    //   }
    //   this.$emit('update:date', value);
    // },

    innerTimeSlot(value) {
      if (this.timeSlot && this.timeSlot.id === value.id) {
        return;
      }
      this.$emit('update:time-slot', value);
    },
  },
};
</script>


<style lang="sass" scoped>
.drop-down
  background-color: $white
  padding:  0 1.25rem 1.25rem
</style>
