<template>

  <div class="time-phase">

    <button
      class="time-phase__back-button back-button"
      type="button"

      @click="$emit('back')"
    >
      <svg
        class="back-button__glyph"
        width="7" height="12" xmlns="http://www.w3.org/2000/svg"
      >
        <use xlink:href="#glyph--angle-left" />
      </svg>
      Назад к датам
    </button>


    <div class="time-phase__date">
      <span class="time-phase__date-label">{{ date.toLocaleString('ru', { month: 'long', day: 'numeric' }) }}</span>
      <button
        type="button"
        class="time-phase__date-switch-button date-switch-button date-switch-button_prev"
        @click="$emit('change-date', -1)"
      >
        <svg
          class="date-switch-button__glyph"
          width="4" height="8" xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#glyph--triangle-left" />
        </svg>
      </button>
      <button
        type="button"
        class="time-phase__date-switch-button date-switch-button date-switch-button_next"
        @click="$emit('change-date', 1)"
      >
        <svg
          class="date-switch-button__glyph"
          width="4" height="8" xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#glyph--triangle-left" transform="rotate(180, 2, 4)" />
        </svg>
      </button>
    </div>

    <div v-if="slotList && slotList.length" class="times">
      <label
        v-for="timeslot in slotList"
        :key="timeslot.id"
        class="times__slot slot"
      >
        <input
          v-model="slotId"
          type="radio"
          name="appointment_time"
          :value="timeslot.id"
          class="slot__radio"
          :disabled="!timeslot.isFree || timeslot.checkIsArchived()"
        >
        <span class="slot__caption">{{ timeslot.getTime() }}</span>
      </label>
    </div>
    <p v-else class="no-times">К сожалению, нет доступного для записи времени.</p>

  </div>
</template>

<script>
import TimeSlot from '@/services/time-slot';

export default {
  name: 'VTimes',

  props: {
    date: {
      type: Date,
      required: true,
    },

    timeSlotList: {
      type: Object,
      default: () => ({}),
    },

    timeSlot: {
      type: TimeSlot,
      default: null,
    },
  },


  data() {
    return {
      innerTimeSlot: this.timeSlot,
      slotId: this.timeSlot ? this.timeSlot.id : null,
    };
  },


  computed: {
    slotList() {
      const key = this.date.toLocaleDateString();

      if (!this.timeSlotList || !this.timeSlotList[key]) {
        return [];
      }

      return this.timeSlotList[key];
    },
  },


  watch: {
    timeSlot(value) {
      if (this.innerTimeSlot && this.innerTimeSlot.id === value.id) {
        return;
      }

      this.$set(this, 'innerTimeSlot', value);
    },

    innerTimeSlot(value) {
      if (this.timeSlot && this.timeSlot.id === value.id) {
        return;
      }

      this.$emit('update:time-slot', value);
    },

    slotId(value) {
      const timeSlot = this.slotList.find(timeSlot => timeSlot.id === value);
      this.$set(this, 'innerTimeSlot', timeSlot);
    }
  }
};
</script>




<style lang="sass" scoped>
.time-phase__back-button,
.time-phase__date-switch-button
  @include clear-button

.back-button
  display: inline-flex
  align-items: center
  color: $blue
  height: 2rem
  margin-bottom: 1.125rem

.back-button__glyph
  display: inline-block
  width: (7 / 16 * 1rem)
  height: (12 / 16 * 1rem)
  margin: auto (14 / 16 * 1rem) auto (3 / 16 * 1rem)

.time-phase__date
  display: flex
  height: 2rem
  margin-bottom: 1.125rem
  align-items: center
  margin-right: (10 / 16 * 1rem)
  width: calc(100% + #{10 / 16 * 1rem})

.time-phase__date-label
  margin-right: auto

.date-switch-button
  display: inline-flex
  align-items: center
  justify-content: center
  width: (24 / 16 * 1rem)
  height: 100%

.times
  display: flex
  flex-wrap: wrap
  color: $gray-dark

.slot
  display: block
  width: calc((100% - #{$gap * ($times-per-row - 1)}) / #{$times-per-row})
  margin: ($gap / 2)

  &:nth-child(#{$times-per-row}n)
    margin-right: 0

  &:nth-child(#{$times-per-row}n + 1)
    margin-left: 0

.slot__radio
  display: none

.slot__caption
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid $blue
  box-sizing: border-box
  height: $slot-height
  cursor: pointer
  border-radius: 3px
  user-select: none

  .slot__radio:disabled ~ &,
  .slot__radio:disabled ~ &:hover
    color: $gray-border
    cursor: default
    border: none
    background: none


  .slot__radio:checked ~ &,
  &:hover
    color: $white
    background-color: $blue

.no-times
  margin: 2.5rem auto
  text-align: center
  color: $gray-border
</style>
